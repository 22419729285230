import DefaultBackground from '@public/assets/images/default-chat-cover.jpg';
import LogoPng from '@public/assets/images/logo-white.png';

import type { CustomizeData } from './types';

export const initialCustomizeValue: CustomizeData = {
  initialMessage: '👋 Hi! I am LiveChatAI, Ask me about anything!',
  removeInitialMessage: false,
  suggestedMessage: '',
  detailedSuggestedMessage: null,
  teaserMessage: '👋 Hi! I am LiveChatAI, Ask me about anything!',
  humanRouting: {
    status: false,
    after: '5',
    platforms: [
      {
        text: 'Talk with agent',
        platform: 'intercom',
      },
    ],
  },
  collectEmail: {
    status: true,
    after: '6',
  },
  theme: {
    color: {
      header: '#1C86FA',
      chatbotMessage: '#F2F3F4',
      userMessage: '#1C86FA',
    },
    headerLogo: LogoPng.src,
    brandLogo: null,
    aiAvatar: null,
    align: 'right',
    removeBranding: false,
    hideTeaserMessage: false,
    humanAgentPreviewText: 'Hello how can we help?',
  },
  fullPage: {
    header: 'Welcome to LiveChatAI World',
    showHumanAvatar: true,
    shortMessage:
      '👋 Hi! We’re LiveChatAI Support team. We would like to help you.',
    slug: null,
    indexable: true,
    metaTitle: null,
    metaDescription: null,
    theme: {
      headerColor: '#1C86FA',
      textColor: '#ffffff',
      bgImage: DefaultBackground.src,
      bgColor: 'black',
    },
    passwordProtection: false,
    password: null,
  },
  slack: {
    username: null,
    avatar: null,
  },
  customCSS: '',
  language: 'ENGLISH',
};
